import * as msal from "@azure/msal-browser";
import Router from "../router/index";

// https://learn.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-sign-in?tabs=javascript2

const config = {
  auth: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: process.env.VUE_APP_AUTHORITY,
    redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false // set to true for IE 11
  }
};

const scopes = ["openid", "api://09d67940-b360-4ea0-9935-c1d11ad2c965/access_as_user"];

const loginRequest = {
  scopes: scopes,
  prompt: 'select_account',
};


const myMsal = new msal.PublicClientApplication(config);

function handleResponse(response) {
  if (response !== null) {
    // Display signed-in user content, call API, etc.
    sessionStorage.setItem("accessToken", response.accessToken);
    sessionStorage.setItem("fullName", response.idTokenClaims.name);
    sessionStorage.setItem("username", response.account.username);
    sessionStorage.setItem("userId", response.account.homeAccountId);
    myMsal.setActiveAccount(response.account.homeAccountId);
    localStorage.setItem("token", response.accessToken);
    localStorage.setItem("azureUser", true);
    var redirectUrl = response.state;
    
    if(redirectUrl == "/adlogin"){
      redirectUrl = "";
    }
    Router.push({ name: 'loginsuccess', query: { nextUrl: redirectUrl } });
  } else {
    // In case multiple accounts exist, you can select
    const currentAccounts = myMsal.getAllAccounts();

    var redirectUrl = window.location.pathname;
    loginRequest.state = redirectUrl;

    if (currentAccounts.length === 0) {
      // no accounts signed-in, attempt to sign a user in
      myMsal.loginRedirect(loginRequest);
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
    } else if (currentAccounts.length === 1) {
      myMsal.loginRedirect(loginRequest);
    }
  }
}

class AzureAuthService {
  constructor() { }

  login(redirectUrl) {
    loginRequest.state = redirectUrl;
    myMsal.handleRedirectPromise().then(handleResponse);
  }

  loggedIn() {
    myMsal.handleRedirectPromise().then(handleResponse);
  }

  getusername() {
    return sessionStorage.getItem("username");
  }

  logout() {
    myMsal.logoutRedirect();
    sessionStorage.clear();
  }

  refreshToken() {
    if (localStorage.getItem("azureUser") == "true") {
      var acquire_config = { account: myMsal.getAllAccounts()[0], scopes: scopes }
      return new Promise((resolve, reject) => {
        return myMsal
          .acquireTokenSilent(acquire_config)
          .then(function (accessTokenResponse) {
            // Acquire token silent success
            // Call API with token
            sessionStorage.setItem("accessToken", accessTokenResponse.accessToken);
            sessionStorage.setItem("fullName", accessTokenResponse.idTokenClaims.name);
            sessionStorage.setItem("username", accessTokenResponse.account.username);
            sessionStorage.setItem("userId", accessTokenResponse.account.homeAccountId);
            myMsal.setActiveAccount(accessTokenResponse.account.homeAccountId);
            localStorage.setItem("token", accessTokenResponse.accessToken);
            localStorage.setItem("azureUser", true);
            // Call your API with token
            resolve();
          })
          .catch(function (error) {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            //if (error instanceof InteractionRequiredAuthError) {
            myMsal.handleRedirectPromise().then(handleResponse);
            resolve()
            //}
          });
      });
    }
  }

}
export default new AzureAuthService();
